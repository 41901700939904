<template>
  <div id="app" class="bg-secondary-100 min-h-screen">
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
  data() {
    return {}
  },
  mounted() {
    if (this.isSamsungBrowser()) {
      document.documentElement.style.fontSize = '17px'
      console.log('字体大小已设置为17px')
    }
  },
  methods: {
    isSamsungBrowser() {
      console.log(navigator.userAgent.toLowerCase())
      const userAgent = navigator.userAgent.toLowerCase()
      return userAgent.match(/sm-/i) == 'sm-'
    },
  },
}
</script>
<style>
#webpack-dev-server-client-overlay {
  display: none;
}
</style>
