<template>
  <div style="padding-bottom: 50px">
    <van-tabbar v-model="active" fixed active-color="#FACE89" inactive-color="#868E96" z-index="99" @change="onChange">
      <van-tabbar-item icon="home-o">
        <span>首页</span>
        <template #icon="props">
          <img :src="props.active ? icon01.active : icon01.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item icon="search">
        <span>会员购</span>
        <template #icon="props">
          <img :src="props.active ? icon02.active : icon02.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item icon="friends-o">
        <span>我的</span>
        <template #icon="props">
          <img :src="props.active ? icon03.active : icon03.inactive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: 'Tabbar',
  data() {
    return {
      active: 0,
      icon01: {
        active: require('@/assets/img/icon-home-active.svg'),
        inactive: require('@/assets/img/icon-home.svg'),
      },
      icon02: {
        active: require('@/assets/img/icon-box-active.svg'),
        inactive: require('@/assets/img/icon-box.svg'),
      },
      icon03: {
        active: require('@/assets/img/icon-user-active.svg'),
        inactive: require('@/assets/img/icon-user.svg'),
      },
    }
  },
  watch: {
    $route() {
      this.init()
    },
  },
  computed: {},
  created() {
    this.init()
  },
  methods: {
    init() {
      // 初始化 active
      const { name } = this.$route
      console.log(name)
      if (name === 'home') {
        this.active = 0
      } else if (name === 'mall') {
        this.active = 1
      } else if (name === 'my') {
        this.active = 2
      } else {
        this.active = null
      }
      console.log(this.active)
    },
    onChange(index) {
      if (index === 0) {
        this.$router.push({ name: 'home' })
      } else if (index === 1) {
        this.$router.push({ name: 'mall' })
      } else if (index === 2) {
        this.$router.push({ name: 'my' })
      }
    },
  },
}
</script>

