import request from '@/utils/request'

// 登录
export function login(parameter) {
  return request({
    url: '/api/client/member/authenticate',
    method: 'post',
    data: parameter,
  })
}

// sso登录
export function ssoLogin(data) {
  return request({
    url: '/api/client/member/authenticate/yd',
    method: 'post',
    data
  })
}

// 发送短信验证码
export function sendCode(params) {
  return request({
    url: '/api/client/member/phone-code',
    method: 'get',
    params,
  })
}

// 测试登录信息
export function getTestUser() {
  return request({
    url: '/api/client/member/test',
    method: 'get',
  })
}

// 当前会员登录信息
export function getMemberMe() {
  return request({
    url: '/api/client/member/me',
    method: 'get',
  })
}

// 校验短信验证码
export function verifyCode(params) {
  return request({
    url: '/api/client/getKey',
    method: 'get',
    params,
  })
}

/**
 * 用户等级配置
 * @returns
 */
export function selectUserLevels() {
  return request({
    url: '/api/client/member/level-configs?projectId=3',
    method: 'get'
  })
}
