import storage from 'store'
import { CITY, USER_INFO } from '@/store/mutation-types'
import { COUPON_TYPE } from '@/config/constants'
import moment from 'moment'

// 隐藏手机号中间4位
export const hidePhone = (phone) => {
  if (!phone) return ''
  return phone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')
}

export const checkPhone = text => {
  const regs = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
  return regs.test(text)

};

export const getCityId = () => {
  const user = storage.get(USER_INFO)
  return storage.get(CITY) ? storage.get(CITY).id : user.city.id
}

export const formatActivityContent = (type, config, separator = ', ', position) => {
  if (!config) {
      return ''
  }

  if (type !== COUPON_TYPE.CUTOFF) {
    console.log('-------', type, config)
    config = config.replace(/\"/g, '"').replace(/value:/g, '"value":')
    config = JSON.parse(config)
  }
  switch (type)
  {
      case COUPON_TYPE.PRICE_1:
        if (['button', 'detail'].includes(position)) {
          return `${config.value}元购`
        }
        return `全场${config.value}元`
      case COUPON_TYPE.HALF_OFF:
        if (['button', 'detail'].includes(position)) {
          return `${config.value}折购`
        }
        return `全场${config.value}折`
      case COUPON_TYPE.EXCHANGE_TICKET:
        if (position === 'button') {
          return `兑换专区`
        } else if (position === 'detail') {
          return `可用券抵扣${config.value}元`
        }
        return `全场商品兑换券抵扣${config.value}元`
      case COUPON_TYPE.FLASH_SALE:
        if (['button'].includes(position)) {
          const tmp = JSON.parse(JSON.stringify(config.items)).sort((a, b) => {
            return a.num - b.num > 0 ? 0 : -1
          })
          return `凑${tmp[0].num}件`
        }
        return config.items.map(r => `${r.num}件${r.value}折`).join(separator)
      case COUPON_TYPE.CUTOFF:
        // const tmp = JSON.parse(JSON.stringify(config)).sort((a, b) => {
        //   return a.useAmountLimit - b.useAmountLimit > 0 ? -1 : 0
        // })
        // const arr = tmp.map(r => `满${r.useAmountLimit}减${r.discountAmount}`)
        // if (['button', 'detail'].includes(position)) {
        //   return arr.slice(0, 3).join(' ')
        // }
        // return arr.join(' ')
        return '满减专区'
  }
}

export const calculateTimeDifference = (startDateString, endDateString) => {
  let start = moment(startDateString)
  let end = moment(endDateString)

  if (end.isBefore(start)) {
    let tmp = start
    start = end
    end = tmp
  }

  var timeDiffInMs = Math.abs(end.valueOf() - start.valueOf()); // 获取两个日期相差的毫秒数

  var diffDays = Math.floor(timeDiffInMs / (1000 * 3600 * 24)); // 计算相差的天数
  var diffHours = Math.floor((timeDiffInMs % (1000 * 3600 * 24)) / (1000 * 3600)); // 计算相差的小时数
  var diffMinutes = Math.floor((timeDiffInMs % (1000 * 3600)) / (1000 * 60));      // 计算相差的分钟数
  var diffSeconds = Math.floor((timeDiffInMs % (1000 * 60)) / 1000);               // 计算相差的秒数

  // return "相差" + diffDays + "天 " + diffHours + "小时 " + diffMinutes + "分钟 " + diffSeconds + "秒";
  return {
    days: diffDays,
    hours: diffHours,
    minutes: diffMinutes,
    seconds: diffSeconds
  }
}

export const copyStr = (text) => {
  navigator.clipboard.writeText(text)
}

export const smoothScrollTop = (ref) => {
  const currentTop = ref.scrollTop
  if (currentTop > 0) {
    window.requestAnimationFrame(() => {
      smoothScrollTop(ref)
    });
    ref.scrollTo(0, currentTop - currentTop / 8);
  }
}

export const formatBannerImage = (url) => {
  return `${url}?x-oss-process=image/resize,w_1000`
}

export const formatCategoryImage = (url) => {
  return `${url}?x-oss-process=image/resize,w_120`
}

export const formatItemImageList = (url) => {
  return `${url}?x-oss-process=image/resize,w_120`
}

export const formatItemImageGrid = (url) => {
  return `${url}?x-oss-process=image/resize,w_300`
}

export const formatItemImageDetail = (url) => {
  return `${url}?x-oss-process=image/resize,w_800`
}

export default { hidePhone }