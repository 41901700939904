<template>
  <van-nav-bar :title="$route.meta.title" left-text="" left-arrow>
    <template #right>
      <van-icon name="ellipsis" />
    </template>
  </van-nav-bar>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {};
  },
  computed: {},
  created() {},
  watch: {},
  methods: {},
};
</script>
