<template>
  <div class="">
    <!-- <Header v-show="$route.meta.hasHeader" /> -->
    <div class="max-w-2xl mx-auto min-height">
      <keep-alive :include="$store.getters.keepAlives">
        <router-view />
      </keep-alive>
    </div>
    <Footer v-if="$route.meta.hasFooter" />
    <Tabbar v-if="$route.meta.hasTabbar" />
  </div>
</template>

<script>
import Header from '@/layout/components/Header.vue'
import Footer from '@/layout/components/Footer.vue'
import Tabbar from "@/layout/components/Tabbar.vue"

export default {
  name: 'Layout',
  components: {
    Header,
    Footer,
    Tabbar
  },
  computed: {
    key() {
      return this.$route.path
    },
  },
}
</script>
<style scoped>
.min-height {
  min-height: calc(100vh - 100px);
}
</style>