<template>
  <div class="p-6 text-center text-[10px] leading-4 text-secondary-400" :class="{ 'bg-white': isLogin }">
    @中国移动通信集团江苏有限公司版权所有 <br />
    苏ICP备11070397号-21 <br />
    (中国移动通信集团委托江苏有限公司支撑XXXX全网平台)
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {}
  },
  computed: {
    // 当前路由是login
    isLogin() {
      return this.$route.name === 'login'
    },
  },
  created() {},
  methods: {},
}
</script>
