import { ADD_KEEP_ALIVE, REMOVE_KEEP_ALIVE, CLEAR_KEEP_ALIVE } from '@/store/mutation-types'

const KeepAlive = {
  state: {
    routes: []
  },

  mutations: {
    [ADD_KEEP_ALIVE]: (state, datas) => {
      datas.forEach(data => {
        if (state.routes.filter(ka => ka === data).length > 0) {
          return
        }
        state.routes.push(data)
      })
    },
    [REMOVE_KEEP_ALIVE]: (state, datas) => {
      datas.forEach(data => {
        const idx = state.routes.indexOf(data)
        if (idx === -1) {
          return
        }
        state.routes.splice(idx, 1)
      })
    },
    [CLEAR_KEEP_ALIVE]: (state) => {
      state.routes = []
    }
  }
}

export default KeepAlive
