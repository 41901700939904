import request from '@/utils/request'

// 微信获取jsConfig
export function getJSConfig(params) {
  return request({
    url: '/api/wx/getJSConfig',
    method: 'get',
    params,
  })
}

// 微信获取授权url
export function getOuthUrl(params) {
  return request({
    url: `/api/wx/auth/getOuthUrl`,
    method: 'get',
    params,
  })
}

// 解析经纬度
export function getLbs(data) {
  return request({
    url: `/api/wx/lbs-get`,
    method: 'post',
    data,
  })
}
