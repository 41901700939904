import Vue from 'vue'
import storage from 'store'
import expirePlugin from 'store/plugins/expire'
import { router } from '@/router'
import { ACCESS_TOKEN, USER_INFO, USER_VIP, LOCATION, CITY } from '@/store/mutation-types'
import { login, getMemberMe } from '@/api/login'
import { hidePhone } from '@/utils/common'
import { getJSConfig } from '@/api/wx'

const user = {
  state: {
    token: '',
    info: null,
    vip: '',
    wxConfig: null,
    location: null,
    city: null,
    indexCategory: undefined
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_INFO: (state, info) => {
      state.info = info
    },
    SET_VIP: (state, vip) => {
      state.vip = vip
      storage.set(USER_VIP, vip)
    },
    SET_WXCONFIG(state, data) {
      state.wxConfig = data
    },
    SET_LOCATION(state, data) {
      state.location = data
      storage.set(LOCATION, data)
    },
    SET_CITY(state, data) {
      state.city = data
      storage.set(CITY, data)
    },
    SET_INDEX_CATEGORY(state, data) {
      state.indexCategory = data
    }
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo)
          .then((response) => {
            storage.set(ACCESS_TOKEN, response.token, 15 * 24 * 60 * 60 * 1000)
            commit('SET_TOKEN', response.token)
            commit('SET_CITY', null)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    SSOLogin ({ commit }, query) {
      return new Promise((resolve, reject) => {
        ssoLogin(query)
          .then(res => {
            console.log(res)
            storage.set(ACCESS_TOKEN, res.token, 15 * 24 * 60 * 60 * 1000)
            commit('SET_TOKEN', res.token)
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 获取用户信息
    GetInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getMemberMe()
          .then((res) => {
            console.log(res)
            res.hidePhone = hidePhone(res.phone)

            // 设置默认会员等级
            if (storage.get(USER_VIP)) {
              if (res.levels && res.levels.includes(storage.get(USER_VIP))) {
                commit('SET_VIP', storage.get(USER_VIP))
              } else if (res.levels.length > 0) {
                commit('SET_VIP', res.levels[0])
              }
            } else {
              const currentVip = res.levels.length > 0 ? res.levels[0] : undefined
              commit('SET_VIP', currentVip)
            }

            commit('SET_INFO', res)
            storage.set(USER_INFO, res)
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 登出
    Logout({ commit, state }) {
      return new Promise((resolve) => {
        commit('SET_TOKEN', '')
        commit('SET_INFO', null)
        commit('SET_VIP', null)
        storage.remove(ACCESS_TOKEN)
        storage.remove(USER_INFO)
        storage.remove(LOCATION)
        storage.remove(CITY)
        storage.remove('openid')
        // setTimeout(() => {
        //   window.location.reload()
        // }, 100)
        resolve()
      })
    },

    // 获取微信地理位置
    GetLocation({ commit }) {
      return new Promise((resolve, reject) => {
        // 获取当前页面的url
        const url = location.href

        getJSConfig({ url }).then((res) => {
          const { appId, timestamp, nonceStr, signature } = res
          console.log(res)
          wx.config({
            debug: false,
            appId,
            timestamp,
            nonceStr,
            signature,
            jsApiList: ['getLocation'],
          })
          wx.ready(() => {
            wx.getLocation({
              type: 'wgs84',
              success: (res) => {
                console.log('微信定位：')
                console.log(res)
                // const { latitude, longitude } = res
                commit('SET_LOCATION', res)
                resolve(res)
              },
              fail: function (err) {
                console.log(err)
                reject(err)
              },
            })
          })
        })
      })
    },
  },
}

export default user
